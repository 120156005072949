import { useState } from 'react'

const useModal = (showOnRender = false) => {
  const [showModal, setShowModal] = useState(showOnRender)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  return {
    showModal,
    toggleModal,
  }
}

export default useModal
