import { useEffect } from 'react'

import { useCookies } from 'react-cookie'

const useSetL2UserCookie = () => {
  const [, setCookie, removeCookie] = useCookies()

  useEffect(() => {
    if (window.location.hostname === 'app.lumosity.com') {
      setCookie('L2User', true, { domain: '.lumosity.com', secure: true, sameSite: 'none' })
    }
  }, [removeCookie, setCookie])
}

export default useSetL2UserCookie
