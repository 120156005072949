import React from 'react'

import { useReactiveVar } from '@apollo/client'
import { Heading3, Subheading2 } from '@lumoslabs/lumosity-storybook'
import Head from 'next/head'
import styled from 'styled-components'

import TopNav from '~/components/TopNav'
import PageContent from '~/components/ui/PageContent'
import { currentUserVar } from '~/graphql/reactive-vars'
import useLocale from '~/hooks/useLocale'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const MobileDevicesInterstitial = (): JSX.Element => {
  const t = useTranslationForNamespace('common')
  const { minNumberOfGamesForLocale } = useLocale()
  const currentUser = useReactiveVar(currentUserVar)

  return (
    <>
      <Head>
        <title>{t('interstitials.mobileDevices.title')}</title>
      </Head>
      {currentUser && <TopNav />}
      <PageContent>
        <PageContent.Header>
          <Heading3 as='h3'>{t('interstitials.mobileDevices.header')}</Heading3>
        </PageContent.Header>
        <PageContent.Main>
          <div>
            <CaptionContainer>
              <Subheading2>
                {t('interstitials.mobileDevices.body', { minNumberOfGames: minNumberOfGamesForLocale })}
              </Subheading2>
            </CaptionContainer>
          </div>
        </PageContent.Main>
      </PageContent>
    </>
  )
}

const CaptionContainer = styled.div`
  margin-top: 16px;
`

export default MobileDevicesInterstitial
