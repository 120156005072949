import React from 'react'

import { Caption1, Caption1Bold } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import { TOTAL_PROGRAM_SESSIONS } from '~/constants'
import Flame from '~/images/Streak/Flame'

interface DayProp {
  label: string
  today: boolean
  streak: boolean
  highlight: boolean
}

export interface StreakCalendarProps {
  totalSessionCount: number
  completedSessionCount: number
  todaysSessionStarted: boolean
  inkBackground?: boolean
}

const StreakCalendar = ({
  totalSessionCount,
  completedSessionCount,
  todaysSessionStarted,
  inkBackground = false,
}: StreakCalendarProps): JSX.Element => {
  const days: DayProp[] = []

  for (let dayIndex = 0; dayIndex < TOTAL_PROGRAM_SESSIONS; dayIndex++) {
    // streak for current date
    const streakActiveThisDay = dayIndex < completedSessionCount
    const todayIndex = todaysSessionStarted ? totalSessionCount - 1 : totalSessionCount

    days.push({
      label: '',
      today: dayIndex == todayIndex,
      streak: streakActiveThisDay,
      highlight: streakActiveThisDay,
    })
  }

  return (
    <Container>
      {days?.map((column, index) => {
        const isFirstHighlight = !days?.[index - 1]?.highlight
        const isLastHighlight = !days?.[index + 1]?.highlight
        return (
          <Column key={column.label + index}>
            {column.streak ? (
              <FlameDot
                isActive={Boolean(column.today) || Boolean(column.highlight)}
                isFirstHighlight={isFirstHighlight}
                isLastHighlight={isLastHighlight}
              >
                <Flame title='Streak' />
              </FlameDot>
            ) : (
              <Dot isActive={Boolean(column.today)} inkBackground={inkBackground} />
            )}
            {column.today ? (
              <Today inkBackground={inkBackground}>{column.label}</Today>
            ) : (
              <Day inkBackground={inkBackground}>{column.label}</Day>
            )}
          </Column>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  height: 70px;
`

const Column = styled.div`
  flex: 1;
  width: 40px;
  max-width: 40px;
`

const BaseDot = styled.div`
  border: 0px;
  border-radius: 14px;
  width: 12px;
  height: 12px;
  margin: 20px auto 14px;
`

const Dot = styled(BaseDot)<{ isActive: boolean; inkBackground: boolean }>`
  background-color: ${({ isActive, inkBackground, theme }) =>
    isActive ? theme.colors.neutral[400] : inkBackground ? theme.colors.neutral[100] : theme.colors.neutral[100]};
`

const FlameDot = styled(BaseDot)<{ isFirstHighlight: boolean; isLastHighlight: boolean; isActive: boolean }>`
  background-color: inherit;
  font-size: 24px;
  height: 24px;
  width: 100%;
  margin-bottom: 14px;
  margin-top: 8px;
  position: relative;
  z-index: 1;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.6')};
  ${({ isActive, isFirstHighlight, isLastHighlight, theme }) =>
    isActive &&
    !(isFirstHighlight && isLastHighlight) &&
    `:before {
      content: ' ';
      background: ${theme.colors.system.tertiaryAccent};
      opacity: .33;
      position: absolute;
      top: 14px;
      height: 10px;
      z-index: -1;
      ${
        isFirstHighlight
          ? `
        width: 50%;
        left: 50%;
      `
          : isLastHighlight
          ? `
          width: 50%;
          left: 0;
        `
          : `
          width: 100%;
          left: 0;
        `
      }
    }`}
`

const Today = styled(Caption1Bold)<{ inkBackground: boolean }>`
  color: ${({ inkBackground, theme }) => (inkBackground ? theme.colors.neutral[100] : theme.colors.neutral[400])};
`

const Day = styled(Caption1)<{ inkBackground: boolean }>`
  color: ${({ inkBackground, theme }) => (inkBackground ? theme.colors.neutral[100] : theme.colors.neutral[300])};
`

export default StreakCalendar
