import { useEffect, useContext, useCallback } from 'react'

import { MarketingEvents } from '~/components/marketing/MarketingProvider'
import { MarketingContext } from '~/context/MarketingContext'

const useMarketingPageView = (name: string) => {
  const { logCustomMarketingEvent } = useContext(MarketingContext)

  const logMarketingPageView = useCallback(
    () => logCustomMarketingEvent(MarketingEvents.ScreenView, { name }),
    [logCustomMarketingEvent, name],
  )

  useEffect(() => {
    logMarketingPageView()
  }, [logMarketingPageView])
}

export default useMarketingPageView
