import React from 'react'

import { ApolloError } from '@apollo/client'
import { Caption1, MicroText1 } from '@lumoslabs/lumosity-storybook'
import dayjs from 'dayjs'
import styled from 'styled-components'

import StreakCalendar from '~/components/streaks/StreakCalendar'
import LoadingOrb from '~/components/ui/LoadingOrb'
import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'
import { TOTAL_PROGRAM_SESSIONS } from '~/constants'
import { useGetTodaysWorkoutQuery, useGetWorkoutDatesQuery } from '~/graphql/generated/schema'
import { useAvailableGames } from '~/hooks/useAvailableGames'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { getDate } from '~/utils/getDate'
import { mapWorkout } from '~/utils/workoutUtils'

function useSessionCount(): {
  loading?: boolean
  error?: ApolloError
  totalSessionCount: number
  completedSessionCount: number
  todaysSessionStarted: boolean
} {
  const availableGameSlugs = useAvailableGames()
  const datesQuery = useGetWorkoutDatesQuery()
  const workoutQuery = useGetTodaysWorkoutQuery({
    variables: {
      knownGameSlugs: availableGameSlugs,
      currentDate: getDate(),
    },
  })

  const defaultValues = {
    totalSessionCount: 0,
    completedSessionCount: 0,
    todaysSessionStarted: false,
  }

  if (datesQuery.loading || datesQuery.error || !datesQuery.data) {
    return { ...datesQuery, ...defaultValues }
  }

  if (workoutQuery.loading || workoutQuery.error || !workoutQuery.data) {
    return { ...workoutQuery, ...defaultValues }
  }

  const dates = datesQuery.data.workoutDates

  const currentDay = dayjs()
  const workoutToday = dates.some((date) => currentDay.isSame(date, 'day'))

  const { slots } = mapWorkout(workoutQuery.data.todaysWorkout)
  const workoutComplete = Object.values(slots).every((s) => s.lastGamePlay)

  const totalSessionCount = dates.length
  const hasIncompleteSession = workoutToday && !workoutComplete
  const completedSessionCount = totalSessionCount - (hasIncompleteSession ? 1 : 0)

  return {
    totalSessionCount,
    completedSessionCount,
    todaysSessionStarted: workoutToday,
  }
}

const StreakCard = (): JSX.Element => {
  const t = useTranslationForNamespace('common')
  const { loading, error, totalSessionCount, completedSessionCount, todaysSessionStarted } = useSessionCount()
  const loaded = !(loading || error)
  if (!loaded) {
    return <LoadingOrb />
  }

  return (
    <CardContainer>
      <CardTitle>
        {t('streaks.sessionsRemaining', {
          remainingCount: TOTAL_PROGRAM_SESSIONS - completedSessionCount,
          totalCount: TOTAL_PROGRAM_SESSIONS,
        })}
      </CardTitle>
      <StreakUnderline />
      <CalendarContainer>
        <StreakCalendar
          totalSessionCount={totalSessionCount}
          completedSessionCount={completedSessionCount}
          todaysSessionStarted={todaysSessionStarted}
          inkBackground={false}
        />
      </CalendarContainer>
      <StreakLengthsContainer>
        <Row>
          <StreakLabelsText>{t('streaks.sessionInfo')}</StreakLabelsText>
        </Row>
      </StreakLengthsContainer>
    </CardContainer>
  )
}

const CardContainer = styled(CardShadowRadius)`
  width: 326px;
  height: 241px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  box-shadow: ${({ theme }) => theme.effects.boxShadow.outset[1]};
`

const CardTitle = styled(MicroText1)`
  color: ${({ theme }) => theme.colors.neutral[300]};
  padding: 18px 18px 8px;
  display: block;
`

const StreakUnderline = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.colors.neutral[100]};
  border: none;
`

const StreakLengthsContainer = styled.div`
  margin: 22px 10px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 1fr;
  text-align: center;
  align-items: center;
  color: ${(props) => props.theme.colors.neutral[400]};
`

const Row = styled.div`
  display: flex;
  > * {
    flex: auto;
  }
`

const StreakLabelsText = styled(Caption1)`
  color: ${(props) => props.theme.colors.neutral[300]};
  margin-top: 8px;
  margin-right: 10px;
`

const CalendarContainer = styled.div`
  color: ${(props) => props.theme.colors.neutral[400]};
`

export default StreakCard
