import { useEffect } from 'react'

import { BodyText3 } from '@lumoslabs/lumosity-storybook'
import { animated, useSpring } from '@react-spring/web'
import lottie from 'lottie-web'
import styled from 'styled-components'

import { springs } from '~/animation/Loading.animation'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const LoadingOrb = () => {
  const t = useTranslationForNamespace('common')
  const loadingTextAnimation = useSpring(springs.fadeIn())

  useEffect(() => {
    const loadingOrb = lottie.loadAnimation({
      container: document.querySelector('#loading-container') as any,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/lottieAnimations/loadingOrb.json',
    })
    return () => {
      loadingOrb.destroy()
    }
  }, [])

  return (
    <LoadingContainer>
      <CenteringContainer>
        <LoadingOrbContainer id='loading-container' />
        <LoadingText as={animated.div} style={loadingTextAnimation}>
          {t('loadingWithEllipses')}
        </LoadingText>
      </CenteringContainer>
    </LoadingContainer>
  )
}

export default LoadingOrb

const LoadingText = styled(BodyText3)`
  color: ${(props) => props.theme.colors.system.primaryAccent};
  text-align: center;
  margin-top: 24px;
`

const LoadingOrbContainer = styled.div`
  width: 120px;
`

const LoadingContainer = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
`

const CenteringContainer = styled.div`
  margin: auto;
`
