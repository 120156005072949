import { config } from '@react-spring/web'

// SPRINGS
const fadeIn = () => ({
  opacity: 1,
  from: {
    opacity: 0,
  },
  delay: 200,
  config: config.molasses,
})

export const springs = {
  fadeIn,
}
