import styled from 'styled-components'

export interface PageContentProps {
  children: React.ReactNode
}

const Container = styled.div`
  display: grid;
  grid-template-rows: minmax(120px, max-content) 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 1%;
  grid-template-columns: repeat(12, 1fr);
  padding: 24px 120px;
  color: ${({ theme }) => theme.colors.neutral[400]};
  ${({ theme }) => theme.mediaQuery.maxWidth.desktop} {
    padding: 24px 117px;
    grid-template-columns: repeat(8, 1fr);
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    padding: 12px 5%;
    grid-template-columns: repeat(4, 1fr);
  }
`

const PageContent = ({ children }: PageContentProps): JSX.Element => <Container>{children}</Container>

PageContent.Header = styled.header`
  grid-column: 3/-1;
  grid-row: 1/1;
  ${({ theme }) => theme.mediaQuery.maxWidth.desktop} {
    grid-column: 1/-1;
  }
`

PageContent.Main = styled.main`
  grid-column: 3/9;
  grid-row: 2/2;
  /* a little extra padding to prevent StreakCard from overlapping with Main content */
  padding-right: 3%;
  ${({ theme }) => theme.mediaQuery.maxWidth.desktop} {
    grid-column: 1/6;
    padding-right: 15%;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.tabletMedium} {
    grid-column: 1/-1;
    padding-right: 0;
  }
`

PageContent.Sidebar = styled.aside`
  grid-column: -1 / -5;
  grid-row: 2/2;
  /* Align Sidebar content to right of grid area */
  justify-self: end;
  ${({ theme }) => theme.mediaQuery.maxWidth.desktopSmall} {
    /* shrink calendar, which may overflow the Sidebar grid area due to fixed dimensions */
    > div {
      transform-origin: 100% 0%;
      transform: scale(0.8);
    }
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.tabletMedium} {
    display: none;
  }
`

export default PageContent
