import type { ApolloError } from '@apollo/client'

import LoadingOrb from '~/components/ui/LoadingOrb'
import { Error } from '~/components/ui/sharedStyledComponents'

interface QueryLoaderProps {
  loading: boolean
  error: ApolloError | undefined
  data: any
  children: any
}

/**
 * QueryLoader conditionally renders the LoadingOrb, an Error, or data returned from query
 */
const QueryLoader: React.FC<QueryLoaderProps> = ({ loading, error, data, children }) => {
  if (loading) {
    return <LoadingOrb />
  }

  if (error) {
    console.error(error)
    return <Error />
  }

  if (!data) {
    console.log('No data returned from query')
    return <Error />
  }

  if (data) {
    return children
  }
}

export default QueryLoader
