import { useFlags } from 'launchdarkly-react-client-sdk'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

interface Flags {
  [key: string]: any
}

const getFeatureFileFlags = () => {
  const flagValues = publicRuntimeConfig.features?.flagValues ?? {}
  const featureFlags = publicRuntimeConfig.features?.flags ?? {}
  const flags = Object.keys(featureFlags).reduce((sum: Flags, key: string) => {
    sum[key] = featureFlags[key]?.on
    return sum
  }, {})
  return { ...flagValues, ...flags }
}

export const useFeatureFlags = () => {
  const featureFileFlags = getFeatureFileFlags()
  const launchDarklyFlags = useFlags()
  return { ...featureFileFlags, ...launchDarklyFlags }
}
