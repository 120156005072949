import { useCallback, useContext } from 'react'

import { Callback } from 'amplitude-js'

import { AnalyticsContext } from '~/context/AnalyticsContext'

type TrackResult = Parameters<Callback>

// Used for Amplitude tracking
export default function useAnalytics() {
  const { client, utmCampaignId, utmCampaignSource, utmCampaignMedium, referrer } = useContext(AnalyticsContext)
  const track = useCallback(
    (eventName: string, eventData: Record<string, any> = {}) => {
      return new Promise<TrackResult>((resolve, _reject) => {
        if (client) {
          client?.logEvent(
            eventName,
            {
              utm_campaign: utmCampaignId,
              utm_source: utmCampaignSource,
              utm_medium: utmCampaignMedium,
              referrer,
              ...eventData,
            },
            (responseCode, responseBody, details) => {
              resolve([responseCode, responseBody, details])
            },
          )
        } else {
          resolve([0, ''])
        }
      })
    },
    [client, referrer, utmCampaignId, utmCampaignMedium, utmCampaignSource],
  )

  return track
}
