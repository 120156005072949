import { useContext, useEffect, useRef } from 'react'

import { useRouter } from 'next/router'

import { PlayContext } from '~/constants'
import { AnalyticsCategory, ScreenName } from '~/context/AnalyticsContext'
import { GameContext } from '~/context/GameContext'
import useAnalytics from '~/hooks/analytics/useAnalytics'
import useCurrentScreen from '~/hooks/analytics/useCurrentScreen'
import useLastCtaClicked from '~/hooks/analytics/useLastCtaClicked'

export default function useTrackScreenView(
  screenNameSnakeCase: ScreenName,
  categorySnakeCase: AnalyticsCategory | PlayContext,
) {
  const [screenName, setScreenName] = useCurrentScreen()
  const [lastCtaClicked] = useLastCtaClicked()
  const { slug } = useContext(GameContext)
  const didSend = useRef(false)

  const track = useAnalytics()
  const router = useRouter()

  const titleCaseStringFromSnakeOrCamel = (string: string) =>
    string
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_

  const categoryTitleCase = titleCaseStringFromSnakeOrCamel(categorySnakeCase)
  const gameSlugSnakeCase = slug?.replace('-', '_')
  useEffect(() => {
    if (screenNameSnakeCase !== screenName && !didSend.current) {
      track(`${categoryTitleCase} Screen View`, {
        category: categorySnakeCase, //the category of the page
        screen_name: screenNameSnakeCase, // the name of the screen
        game: gameSlugSnakeCase, // the name of the game in snake cases, if present
        cta_origin: lastCtaClicked,
        cta_origin_screen_name: screenName,
      })
      didSend.current = true
    }
    // Set the screen name in the context to be used for future events
    setScreenName(screenNameSnakeCase)
  }, [
    categorySnakeCase,
    screenNameSnakeCase,
    categoryTitleCase,
    router.asPath,
    track,
    setScreenName,
    gameSlugSnakeCase,
    screenName,
    lastCtaClicked,
  ])
}

// Amplitude event properties:
// event name: `${category} Screen View`
// Properties:
// category: enum AnalyticsCategory
// screen_name: enum ScreenName
// game: game_slug_snake_case, // game name in snake case for game-related events
// cta_origin: cta_origin // name of the CTA clicked to get here
// cta_origin_screen_name: cta_origin_screen_name // name of page on which the CTA was clicked to get here
