import Modal from 'styled-react-modal'

import { theme } from '~/styles/theme'

const StyledModal = Modal.styled`
  background: ${(props: { theme: typeof theme }) => props.theme.colors.background.card};
  border-radius: 16px;
  margin: 0 auto;
  min-width: 342px;
  padding: 40px;
  max-height: 75%;
  max-width: 50%;
  @media (min-width: 768px) {
    width: 800px;
  }
  overflow-y: auto;
`

export default StyledModal
