import { useCallback } from 'react'

import { BodyText1, Heading2 } from '@lumoslabs/lumosity-storybook'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'

import StyledModal from '~/components/modals/StyledModal'
import ButtonWithTracking from '~/components/ui/ButtonWithTracking'
import { useFeatureFlags } from '~/hooks/useFeatureFlags'
import useModal from '~/hooks/useModal'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { useWasUserMigrated } from '~/hooks/useWasUserMigrated'

export const MigrationModal = () => {
  const featureFlags = useFeatureFlags()
  const isModalEnabled = featureFlags['show-migrated-user-landing']
  const { showModal, toggleModal } = useModal(true)
  const [{ welcomeModalSeen }, setCookie] = useCookies(['welcomeModalSeen'])
  const t = useTranslationForNamespace('home.migrationWelcome')
  const wasUserMigrated = useWasUserMigrated()

  const modalVisible = isModalEnabled && showModal && !welcomeModalSeen && wasUserMigrated

  const closeModal = useCallback(() => {
    setCookie('welcomeModalSeen', true)
    toggleModal()
  }, [setCookie, toggleModal])

  return (
    <Modal isOpen={modalVisible} onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
      <TextContainer>
        <Heading2>{t('header')}</Heading2>
        <BodyText>{t('message1')}</BodyText>
        <BodyText>{t('message2')}</BodyText>
        <ButtonWithTracking onClick={closeModal} textKey='iUnderstand' />
      </TextContainer>
    </Modal>
  )
}

const Modal = styled(StyledModal)`
  background-image: url('/assets/images/tour-bg-graphic.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: local;
  width: 570px;
  padding-top: 270px;
`

const BodyText = styled(BodyText1)`
  text-align: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`
